<template>
  <div class="user-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="elevator/archive/iot/page">
      <el-button
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="handleSeniorClick">
        {{$l("common.search", "高级")}}
      </el-button>
      <el-button
        v-if="$auth('远程监测导出')"
        slot="btn"
        :disabled="status==='loading'"
        size="mini"
        style="height: 26px;padding: 0 10px"
        type="primary"
        @click="exportExcelData">
        {{$l("common.search", "导出")}}
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="楼盘名称">
            <el-input v-model.trim="filter.realEstateName" clearable></el-input>
          </vm-search>
          <vm-search label="楼宇名称">
            <el-input v-model.trim="filter.buildingName" clearable></el-input>
          </vm-search>
          <vm-search label="注册代码">
            <el-input v-model.trim="filter.regCode" clearable></el-input>
          </vm-search>
          <vm-search label="出厂编号">
            <el-input v-model.trim="filter.factoryCode" clearable></el-input>
          </vm-search>
          <vm-search label="设备编号">
            <el-input v-model.trim="filter.deviceName" clearable></el-input>
          </vm-search>
          <vm-search label="电梯在使用单位内部编号">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <vm-search label="物联网协议来源">
            <vm-dict-select v-model="filter.iotProtocolSource" type="iotProtocolSource"></vm-dict-select>
          </vm-search>
          <vm-search label="是否在线">
            <el-select v-model="filter.online">
              <el-option :value="true" label="是"></el-option>
              <el-option :value="false" label="否"></el-option>
            </el-select>
          </vm-search>
          <vm-search label="设备状态">
            <vm-dict-select v-model="filter.status" type="iotDeviceStatus"></vm-dict-select>
          </vm-search>
          <vm-search v-if="senior" label="电梯启用日期">
            <el-date-picker
              v-model="filter.factoryDate"
              type="date"
              value-format="yyyy-MM-dd hh:mm:ss"
              placeholder="选择日期">
            </el-date-picker>
          </vm-search>
          <vm-search v-if="senior" label="使用类型">
            <vm-dict-select v-model="filter.useType" type="elevatorUseType"></vm-dict-select>
          </vm-search>
          <vm-search v-if="senior" label="控制系统">
            <vm-dict-select v-model="filter.controlSystem" type="elevatorControlSystem"></vm-dict-select>
          </vm-search>
          <vm-search v-if="senior" label="是否有机房">
            <el-select v-model="filter.machineRoom">
              <el-option :value="true" label="是"></el-option>
              <el-option :value="false" label="否"></el-option>
            </el-select>
          </vm-search>
          <vm-search v-if="senior" label="电梯品牌">
            <el-input v-model.trim="filter.brandName" clearable></el-input>
          </vm-search>
          <vm-search v-if="senior" label="电梯型号">
            <el-input v-model.trim="filter.modelName" clearable></el-input>
          </vm-search>
        </div>
      </template>

      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="realEstateName" label="楼盘楼宇" align="center" width="170">
        <template slot-scope="scope">
          {{scope.row.realEstateName+'-'+scope.row.buildingName}}
        </template>
      </el-table-column>
      <el-table-column prop="elevatorName" label="电梯在使用单位内部编号" align="center" width="170"></el-table-column>
      <el-table-column prop="regCode" label="注册代码" align="center" width="170"></el-table-column>
      <el-table-column prop="factoryCode" label="出厂编号" align="center" width="170"></el-table-column>
      <el-table-column prop="protocolSourceDesc" label="协议来源-设备型号" align="center">
        <template slot-scope="scope">
          {{scope.row.protocolSourceDesc+'-'+scope.row.deviceModelDesc}}
        </template>
      </el-table-column>
      <el-table-column prop="deviceName" label="设备编号-设备端口" align="center">
        <template slot-scope="scope">
          {{scope.row.deviceName+'-'+scope.row.devicePort}}
        </template>
      </el-table-column>
      <el-table-column label="是否在线-上次心跳时间" align="center" width="90">
        <template slot-scope="scope">
          <el-tag :type="scope.row.online?'success':'info'">{{scope.row.online?'在线':'离线'}}</el-tag>{{scope.row.lastHeartbeatTime}}
        </template>
      </el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="200">
        <template slot-scope="scope">
          <el-button v-if="$auth('远程监测详情')" type="primary" @click="$refs.elevatorDetail.open(scope.row.id)">{{$l("common.edit", "详情")}}</el-button>
          <!--          <el-button type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>-->
          <el-button
            v-if="$auth('远程监测监控')"
            :title="!(scope.row.elevatorIot&&scope.row.elevatorIot.iotDeviceCode)?'未绑定终端设备':scope.row.elevatorIot&&scope.row.elevatorIot.online?'在线':'离线'"
            type="success"
            @click="gotoMonitor(scope.row.id)">
            {{$l("monitor.monitor", "监控")}}
          </el-button>
          <el-button v-if="os==='new'" type="primary" @click="addMenu(scope.row)">{{$l("common.detail", "桌面快捷方式")}}</el-button>
          <!--          <el-button type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>-->
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <elevator-detail ref="elevatorDetail" @save-success="getList(-1)"></elevator-detail>
  </div>
</template>
<script>
  import EditPage from "../ElevatorEdit.vue";
  import { getWidth,export2Excel} from "@/util";
  import ElevatorDetail from "./ElevatorDetail";
  import moment from "moment";
  import loginUtil from "@/util/loginUtil";
  export default {
    components: {EditPage,ElevatorDetail},
    props:{
      menus:{
        type: Array,
        default:function() {
          return [];
        },
      }},
    data() {
      return {
        filter: {
          name: "",
          regCode: "",
          factoryCode: "",
          rescueCode: "",
          type: "",
          status: "",
          projectName: "",
          deviceNo: "",
          factoryDate:"",
          companyId: loginUtil.getCompanyCode(),
          maintenanceType:"regulation",
        },
        senior: false,
        data:[],
        width:200,
        os:localStorage.getItem("os"),
      };
    },
    mounted() {
      this.getList(1);
      this.getTreeList();
    },
    methods: {
      exportExcelData() {
        const title={
          realEstateName:"楼盘",
          buildingName:"楼宇",
          elevatorName:"电梯在使用单位内部编号",
          regCode:"注册代码",
          protocolSourceDesc:"协议来源",
          deviceModelDesc:"设备型号",
          deviceName:"设备编号",
          devicePort:"设备端口",
          online:"是否在线",
          lastHeartbeatTime:"上次心跳时间",
        };
        this.$http.get("elevator/archive/iot/page",{...this.filter,pageSize:300000}).then(res=>{

          export2Excel(title,res.records,`电梯档案-远程检测-${res.total}条-${moment(new Date()).format("YYYYMMDDhhmmss")}`);
        });
      },
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },
      deleteRow(row) {
        this.$confirm(this.$l("common.deleteTip", "确定删除{key}吗？", {key: row.elevatorName}), this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete(`/elevator-archive/${row.id}`).then(() => {
            this.getList(-1);
            this.$message.success(row.elevatorName + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
      gotoMonitor(elevatorId) {
        if (this.os==="new") {
          let obj ={};
          this.menus.forEach(item=>{
            if (item.menuName==="监控页面") {
              obj=item;
            }
          });
          this.$emit("navigator",obj);
          this.$emit("query",{elevatorId});
        }else {
          let routeUrl = this.$router.resolve({
            path: "/monitor/LiftMonitor",
            query: {elevatorId},
          });
          window.open(routeUrl.href, "_blank");

        }

      },
      addMenu(row) {
        this.$emit("addMenu",row);
      },
      handleSeniorClick() {
        this.senior=!this.senior;
      },
      goToBasicFault(row) {
        if (this.os==="new") {
          let obj ={};
          this.menus.forEach(item=>{
            if (item.menuName==="遥监设备") {
              obj=item;
            }
          });
          sessionStorage.setItem("iotCode",row.elevatorIot.iotDeviceName);
          this.$emit("navigator",obj);
          this.$emit("query",{iotCode:row.elevatorIot.iotDeviceName});
        }else {
          this.$router.push({path: "/device/List",query:{iotCode:row.elevatorIot.iotDeviceName}});

        }
      },
      getTreeList() {
        let filter = this.filter;
        if (this.filterFun) {
          let filterCopy = JSON.parse(JSON.stringify(filter));
          filter = this.filterFun(filterCopy);
        }
        let http =  this.$http.get("/basic/district/tree/locate-and-with-real-estate");
        http.then(data => {
          this.data = data;
          this.width=100+14*getWidth(data);

        }).catch(() => {
          this.status = "error";
        });
      },
      handleNodeClick(e) {

        if (e.other!=="district") {
          this.filter.realEstateName=e.label;
          this.getList(-1);
        }

      },
    },
  };
</script>
<style lang="scss" scoped>
.treeBox{
  border: 1px solid #E6E6E6;
  padding: 10px;
  margin-right: 10px;
  width: calc(100% - 1300px);
  max-height: 1080px;
  overflow-y: auto;
}
</style>
