
<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    title="转安规"
    width="900px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="formFillWidth"
      :label-width="$l('elevator.labelWidth','110px')">
      <el-tag class="tag">设备信息</el-tag><el-button size="small" type="text" @click="$refs.deviceSelect.open(id)">选择设备</el-button>
      <div class="vm-separate content">
        <div>设备型号:{{device.iotDeviceModelDesc}}</div>
        <div>设备编号:{{device.iotDeviceName}}</div>
        <div>端口:{{device.iotDevicePort}}</div>
      </div>
      <div class="vm-separate content">
        <div>是否在线:{{device.online?"是":"否"}}</div>
        <div>最近心跳时间:{{device.lastHeartbeatTime}}</div>
        <div></div>
      </div>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>

    <device-select ref="deviceSelect" @select="deviceSelect"></device-select>
  </el-dialog>
</template>

<script>
  // import VmPointMap from "@/components/VmPointMap";
  import DeviceSelect from "@/common/select/DeviceSelect";

  export default {
    components: {DeviceSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        tabActive: "first",
        floorStart: 0,
        floorEnd: 0,
        device:{
        },
        model:{},
      };
    },

    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`elevator-archive/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = {id:this.model.id,...data};
            console.log(this.model,280);
            if (!this.model.elevatorIot) {
              this.model.elevatorIot={};
            }
            // this.initMap();
          });
        } else {
          // this.initMap();
        }
      },



      deviceSelect(row) {
        this.$set(this.model.elevatorIot,"iotDeviceId", row.id);
        this.$set(this.model.elevatorIot,"iotDeviceName", row.name);
        this.$set(this.model.elevatorIot,"iotProtocolSource", row.iotProtocolSource);
        this.$set(this.model.elevatorIot,"remoteAddress", row.remoteAddress);
        this.$set(this.model.elevatorIot,"online", row.online);
        this.$set(this.model.elevatorIot,"lastHeartbeatTime", row.lastHeartbeatTime);
        if (row.iotProtocolSource==="ger") {
          this.$set(this.model.elevatorIot,"iotCode", row.name+":"+this.model.elevatorIot.iotDevicePort);
        }
      },

      clearDevice() {
        this.model.deviceId = 0;
        this.model.deviceNo = "";
      },


      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;

            // return false;
            this.$http
              .save("elevator-archive", this.model)
              .then((res) => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                console.log(res,357);
                if (res===false) {
                  this.$message.error("保存失败");
                }else {
                  this.$message.success("保存成功");
                }
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },



    },
  };
</script>

<style lang="scss" scoped>
</style>
