<template>
  <el-tabs value="first" @tab-click="handleClick">
    <el-tab-pane v-if="$auth('电梯档案常规信息')" label="常规信息" name="first"><elevator-list></elevator-list></el-tab-pane>
    <el-tab-pane v-if="$auth('电梯档案远程监测')" label="远程监测" name="second"><elevator-remote-list></elevator-remote-list></el-tab-pane>
    <!--    <el-tab-pane v-if="$auth('电梯档案层站楼')" label="运行报告" name="fifth"><elevator-report></elevator-report></el-tab-pane>-->
  </el-tabs>
</template>

<script>
  import ElevatorList from "@/views/elevator/convention/ElevatorList";
  import ElevatorRemoteList from "@/views/elevator/convention/ElevatorRemoteList";

  // import ElevatorReport from "@/views/elevator/ElevatorReport";
  export default {
    components:{ ElevatorRemoteList,ElevatorList},
  };
</script>

<style scoped>

</style>
